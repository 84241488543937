import React, { useEffect, useMemo, useState } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { Input, Space, Menu, Checkbox, Dropdown, Button, Select } from 'antd';
import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { useLocation } from '@reach/router';
import API from '@/models';
import { Department } from '@/types/department';
import { EmplTypeList, WorkSiteList } from '@/constants/job';
import { EmploymentType, WorkSite } from '@/types/job';
import './index.scss';

const JobFilters = () => {
  const URL = useStaticQuery(graphql`
    query UrlQuery {
      configJson {
        url {
          career_url
        }
      }
    }
  `);

  // 当前的筛选项
  const [keyword, setKeyword] = useState<string>('');
  const [deptIds, setDeptIds] = useState<string[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [employmentTypes, setEmploymentTypes] = useState<string[]>([]);
  const [workSite, setWorkSite] = useState<WorkSite | undefined>();

  // 后端返回的部门列表和地点列表
  const [deptList, setDeptList] = useState<Department[]>([]);
  const [locList, setLocList] = useState<{label: string, value: string}[]>([]);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const keyword = searchParams.get('keyword') || '';
    const deptIds = searchParams.getAll('dept')
    const locations = searchParams.getAll('loc');
    const employmentTypes = searchParams.getAll('empl_type');
    const workSite = searchParams.get('ws');
    setKeyword(keyword);
    setDeptIds(deptIds);
    setLocations(locations);
    setEmploymentTypes(employmentTypes);
    setWorkSite(workSite);
  }, [search]);

  // 当前query
  const query = useMemo(() => {
    return ({
      keyword,
      dept: deptIds,
      loc: locations,
      empl_type: employmentTypes,
      ws: workSite
    })
  }, [keyword, deptIds, locations, employmentTypes, workSite]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    handleChangeQuery();
  }, [keyword, deptIds, locations, employmentTypes, workSite])

  // 获取地点列表
  async function getAllLocations() {
    const { list } = await API.getLocationList();
    const options = list.map(item => ({
      label: item.location,
      value: item.id
      })
    )
    setLocList(options);
  }

  // 获取部门列表
  async function getAllDepartments() {
    const { list }  = await API.getDepartmentList();
    setDeptList(list);
  }

  function handleSelectLoc(value: string) {
    if (locations.includes(value)) {
      setLocations(locations.filter((item) => item !== value));
    } else {
      setLocations([...locations, value]);
    }
  }

  function handleSelectDept(value: string) {
    if (deptIds.includes(value)) {
      setDeptIds(deptIds.filter((item) => item !== value));
    } else {
      setDeptIds([...deptIds, value]);
    }
  }

  function handleSelectEmplType(value: EmploymentType) {
    if (employmentTypes.includes(value)) {
      setEmploymentTypes(employmentTypes.filter((item) => item !== value));
    } else {
      setEmploymentTypes([...employmentTypes, value]);
    }
  }

  function handleSelectWorkSite(value: WorkSite) {
    setWorkSite(value);
  }

  function handleClearFilters() {
    setKeyword('');
    setDeptIds([]);
    setLocations([]);
    setEmploymentTypes([]);
    setWorkSite(undefined);
  }

  async function init() {
    try {
      setLoading(true);
      await getAllDepartments();
      await getAllLocations();
      setLoading(false);
    }catch (err){
      console.log(err);
    }
  }

  async function handleChangeQuery(){
    let queryStr = '?';

    Object.entries(query).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if(value.length){
          value.forEach((v) => {
            queryStr += `${key}=${v}&`;
          });
        }
      } else {
        if(value)
          queryStr += `${key}=${value}&`;
      }
    });

    await navigate(`${URL.configJson.url.career_url}${queryStr}`);
  }

  return (
    <div className="job-filters">
      { !loading && <div className="job-filters__main">
        <Input value={keyword} placeholder="Search By Job Title, or Keyword" onChange={(e) => setKeyword(e.target.value)}/>

        <Dropdown overlay={
          <Menu>
          {locList.map((option, index) => (
            <Menu.Item key={option.value} onClick={() => handleSelectLoc(option.value)}>
              <Checkbox checked={locations.includes(option.value)}/>
              <span style={{marginLeft: '5px'}}>{option.label}</span>
            </Menu.Item>
          ))}
          </Menu>}>
          <Button className={`${locations.length ? 'active' : ''}`}>
            <Space>
              Location{locations.length ? `(${locations.length})` : ''}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>

        <Dropdown overlay={
          <Menu>
            {Boolean(deptList.length) && deptList.map((option, index) => (
              <Menu.Item key={option.id} onClick={() => handleSelectDept(option.id)}>
                <Checkbox checked={deptIds.includes(option.id)}/>
                <span style={{marginLeft: '5px'}}>{option.name}</span>
              </Menu.Item>
            ))}

            {!Boolean(deptList.length) && (
                <div className="p-1">
                  No Data
                </div>
            )}
          </Menu> }>
          <Button className={`${deptIds.length ? 'active' : ''}`}>
            <Space>
              Department{deptIds.length ? `(${deptIds.length})` : ''}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>

        <Dropdown overlay={
          <Menu>
            {EmplTypeList.map((option, index) => (
              <Menu.Item key={option.value} onClick={() => handleSelectEmplType(option.value)}>
                <Checkbox checked={employmentTypes.includes(option.value)}/>
                <span style={{marginLeft: '5px'}}>{option.label}</span>
              </Menu.Item>
            ))}
          </Menu>}>
          <Button className={`${employmentTypes.length ? 'active' : ''}`}>
            <Space>
              Type{employmentTypes.length ? `(${employmentTypes.length})` : ''}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>

        {/*<Dropdown overlay={*/}
        {/*  <Menu>*/}
        {/*    {WorkSiteList.map((option) => (*/}
        {/*      <Menu.Item key={option.value} onClick={() => handleSelectWorkSite(option.value)}>*/}
        {/*        { workSite ===  option.value && <CheckOutlined /> }*/}
        {/*        <span style={{marginLeft: '5px'}}>{option.label}</span>*/}
        {/*      </Menu.Item>*/}
        {/*    ))}*/}
        {/*  </Menu>}>*/}
        {/*  <Button className={`${workSite ? 'active' : ''}`}>*/}
        {/*    <Space>*/}
        {/*      Work Site{workSite ? `(${WorkSiteList.find(item => item.value === workSite)?.label})` : ''}*/}
        {/*      <DownOutlined />*/}
        {/*    </Space>*/}
        {/*  </Button>*/}
        {/*</Dropdown>*/}

        <Button onClick={ handleClearFilters } type="link">Clear</Button>
      </div> }
    </div>
  )
}

export default JobFilters;
