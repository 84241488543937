import { graphql, navigate } from 'gatsby';
import { Spin, Empty } from "antd";
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { cloneDeep, debounce, pickBy } from 'lodash';
import { CheckOutlined } from '@ant-design/icons';
import JobFilters from '@/components/JobFilters/index';
import JobList from '@/components/JobList/index';
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import { Job } from '@/types/job';
import API from '@/models';
import { scrollToAnchor } from '@/utils/scroll';

const DEFAULT_SEARCH_PARAMS = {
  job_name: '',
  department_ids: [],
  location_ids: [],
  employment_type: [],
  page: 1,
  page_size: 10,
};

const Careers = props => {
  const careers = props.data.careers.edges;
  const { intro } = props.data;

  // 后端需要的查询参数
  const [params, setParams] = useState(cloneDeep(DEFAULT_SEARCH_PARAMS));

  const [jobList, setJobList] = useState<Job[]>([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const { search } = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    // 当前的筛选项
    const job_name = searchParams.get('keyword') || '';
    const department_ids = searchParams.getAll('dept');
    const location_ids = searchParams.getAll('loc');
    const employment_types = searchParams.getAll('empl_type');
    const work_site = searchParams.get('ws');
    setParams({...params, job_name, department_ids, location_ids, employment_types, work_site});
  }, [search]);

  useEffect(() => {
    debouncedSearch(params);
  }, [params]);

  function filterEmptyParams(params: any) {
    return pickBy(params, (value) => (!Array.isArray(value) && !!value) || (Array.isArray(value) && value.length));
  }

  const debouncedSearch = useCallback(debounce(async (curParams) => {
    setLoading(true);
    scrollToAnchor('#job-list');

    try {
      const { list, total } = await API.getJobList(filterEmptyParams(curParams));
      setJobList(list);
      setTotal(+total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, 300), [])

  function handlePaginationChange(page: number, pageSize: number) {
    params.page = page;
    params.page_size = pageSize;
    debouncedSearch(params);
  }

  return (
    <Layout bodyClass="page-careers">
      <SEO title="Careers" />

      <div className="page-careers__content">
        <div className="page-careers__main">
          <div className="page-careers__main-left">
            <h2>Location</h2>
            <p>We're hiring primarily in the San Francisco Bay Area, with an office in Santa Clara. As a dynamic startup, we value the collaborative spirit of in-person work. We also remain open to remote arrangements for exceptional candidates.</p>
            <h2>Benefits</h2>
            <span>We offer the following employee benefits:</span>

            <div className="page-careers__benefit">
              <span><CheckOutlined /> Competitive compensation</span>
              <span><CheckOutlined /> Medical, dental, and vision insurance</span>
              <span><CheckOutlined /> Visa sponsorship for new hires</span>
              <span><CheckOutlined /> Generous paid time off</span>
              <span><CheckOutlined /> Flexible working time</span>
            </div>
          </div>

          <div className="page-careers__main-right" id="job-list">
            <h2>Open Roles</h2>
            <JobFilters />

            {loading ?
              <div className="flex-grow-1 d-flex justify-content-center align-items-center overflow-hidden" style={{minHeight: '3rem'}}><Spin
                size="large" /></div> :
              total ?
                <JobList jobList={jobList} page={params.page} pageSize={params.page_size} total={total}
                         handlePaginationChange={handlePaginationChange} />
                :
                <div className="flex-grow-1 d-flex justify-content-center align-items-center">
                  <Empty description="No Job" />
                </div>
            }
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query CareersQuery {
    careers: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/careers/.*/" } }, sort: { fields: [frontmatter___weight], order: ASC }) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            description
          }
        }
      }
    }
    intro: markdownRemark(fileAbsolutePath: { regex: "/(careers.md)/" }) {
      html
      frontmatter {
        title
        image
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
      }
    }
  }
`;

export default Careers;
