import React from 'react';
import { Job, WorkSite } from '@/types/job';
import { SendOutlined } from '@ant-design/icons';
import { Button, Card, Pagination, Tag } from 'antd';
import { navigate } from 'gatsby';
import { EmplTypeList } from '@/constants/job';
import { useSiteConfig } from '@/hooks/SiteConfig';
import { Tab } from '@/constants/apply-job';
import './index.scss';

const Jobs = ({ jobList, page, pageSize , total, handlePaginationChange}: { jobList: Job[], page: number, pageSize: number, total: number, handlePaginationChange: (page: number, pageSize: number) => void }) => {
  const { apply_job_url } = useSiteConfig();

  function handleApplyJob(id: string, tab: Tab) {
    navigate(`${apply_job_url}?id=${id}&tab=${tab}`);
  }

  return (
    <div className="job-list flex-grow-1 d-flex flex-column">
      <div className="job-list__content">
        {
          jobList.map(job => (
            <Card key={job.id} className="job-list__item">
              <div className="d-flex justify-content-between align-top mb-1">
                <div className="job-list__item-name" onClick={ () => handleApplyJob(job.id, Tab.Overview)}>{job.name}</div>
                <Button color="primary" variant="filled" size="small" icon={<SendOutlined />} onClick={ () => handleApplyJob(job.id, Tab.Apply)}>Apply</Button>
              </div>
              <div className="job-list__desc">
                <div>{`${job.locations.join(' - ')} ｜ ${job.department} ｜ ${job.employment_types.map(type => (EmplTypeList.find(item => item.value === type)!).label).join(' - ')}`}</div>
              </div>
            </Card>
          ))
        }
      </div>

      <div className="job-list__pagination">
        <Pagination style={{ textAlign: 'right' }}
                    total={total}
                    current={page}
                    defaultPageSize={pageSize}
                    showQuickJumper
                    showTotal={(total) => `Total ${total} items`}
                    onChange={(page, page_size) => handlePaginationChange(page, page_size)}
        />
      </div>
    </div>
  );
};

export default Jobs;
